.select {
    :global {
        .ant-select-item.ant-select-item-option {
            padding: 8px 12px;
        }
    }
}

.selectWrap {
    :global {
        .ant-select.ant-select-outlined {
            height: 46px;
        }
    }
}

.photoListWarp {
    :global {
        .ant-row > div {
            width: 100%;
        }

        .ant-image .ant-image-img {
            height: 100%;
        }
    }
}
